<template>
    <div class="container">
        <Qr  :url="url"></Qr>

    </div>
</template>
<script>
    import Qr from "@/views/components/qr"
    import {getOrigin} from "@/utils/common"
    export default {
        components:{
            Qr
           // vueQr,

        },
        data(){
            return {
                url:getOrigin()+"/electricity",
               // imageUrl: require("@/assets/logo.png"),
            }
        }
    }
</script>

<style scoped>

</style>
